<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :routeback="routeback"
  >
    <FormField
      label="E-mail"
      v-model="form.email"
      :validation="$v.form.email"
    />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { required } from 'vuelidate/lib/validators'
import locationsMixin from '@/mixins/locations'
import { mapGetters } from 'vuex'
import { USUARIO_CREATE } from '@/store/actions/cliente'

export default {
  name: 'ClienteUsuarioForm',
  mixins: [locationsMixin],
  components: {
    SidePopup,
    FormField,
    FormButton
  },
  validations: {
    form: {
      email: { required }
    }
  },
  data: () => ({
    form: {
      email: ''
    },
    idClient: '',
    idcadastroextracliente: '',
    idfornecedor: ''
  }),
  created() {
    this.idClient = this.$route.params.idclient
    this.idcadastroextracliente = this.$route.params.idcadastroextracliente
    this.idfornecedor = this.$route.params.idfornecedor
  },
  computed: {
    ...mapGetters(['getFornecedorTag']),
    title() {
      return 'Novo usuário'
    },
    routeback() {
      return `/fornecedor/cadastro/cliente/${this.idClient}/lista/usuario`
    }
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const idcadastroextracliente = this.idcadastroextracliente
      const idfornecedor = this.idfornecedor

      let payload = {
        ...this.form,
        idcadastroextracliente,
        idfornecedor
      }

      this.handleSubmit(
        USUARIO_CREATE,
        payload,
        'Usuário cadastrado com sucesso'
      )
    },
    handleSubmit(action, payload, msg) {
      this.$store.dispatch(action, payload).then(() => {
        this.$vueOnToast.pop('success', msg)
        this.$router.replace(this.routeback)
        this.$emit('close')
      })
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>
