<script>
export default {
  computed: {
    brazilianStates() {
      return [
        { text: 'AC', value: 'AC' },
        { text: 'AL', value: 'AL' },
        { text: 'AM', value: 'AM' },
        { text: 'AP', value: 'AP' },
        { text: 'BA', value: 'BA' },
        { text: 'CE', value: 'CE' },
        { text: 'ES', value: 'ES' },
        { text: 'DF', value: 'DF' },
        { text: 'GO', value: 'GO' },
        { text: 'MA', value: 'MA' },
        { text: 'MT', value: 'MT' },
        { text: 'MG', value: 'MG' },
        { text: 'MS', value: 'MS' },
        { text: 'PA', value: 'PA' },
        { text: 'PB', value: 'PB' },
        { text: 'PE', value: 'PE' },
        { text: 'PI', value: 'PI' },
        { text: 'PR', value: 'PR' },
        { text: 'RN', value: 'RN' },
        { text: 'RR', value: 'RR' },
        { text: 'RO', value: 'RO' },
        { text: 'RJ', value: 'RJ' },
        { text: 'RS', value: 'RS' },
        { text: 'SE', value: 'SE' },
        { text: 'SC', value: 'SC' },
        { text: 'SP', value: 'SP' },
        { text: 'TO', value: 'TO' }
      ]
    },
    countries() {
      return [
        { text: 'Argentina', value: 'Argentina' },
        { text: 'Bolívia', value: 'Bolívia' },
        { text: 'Brasil', value: 'Brasil' },
        { text: 'Chile', value: 'Chile' },
        { text: 'Colômbia', value: 'Colômbia' },
        { text: 'Equador', value: 'Equador' },
        { text: 'Guiana', value: 'Guiana' },
        { text: 'Guiana Francesa', value: 'Guiana Francesa' },
        { text: 'Paraguai', value: 'Paraguai' },
        { text: 'Peru', value: 'Peru' },
        { text: 'Suriname', value: 'Suriname' },
        { text: 'Uruguai', value: 'Uruguai' },
        { text: 'Venezuela', value: 'Venezuela' }
      ]
    }
  }
}
</script>
